<template>
    <div class="bk-testimonial-area pt-0 bg_color--5 poss_relative pt_sm--0">
        <div class="container section-pt-xl">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center wow move-up">
                        <h3 class="theme-color">{{ data.sectionSubTitle }}</h3>
                        <h2>{{ data.sectionTitle }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonial-wrapper testimonial--horizontal testimonial--horizontal--active pagination-style-01 mt--80 h-100 wow move-up">
            <div class="testimonial-container">
                <swiper :options="swiperOption">
                    <div class="swiper-slide h-auto" v-for="testimonial in data.testimonials" :key="testimonial.id">
                        <TestimonialItem :testimonial="testimonial" />
                    </div>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
    import data from '../../data/testimonial.json';

    export default {
        components: {
            TestimonialItem: () => import('../TestimonialItem')
        },

        data () {
            return {
                data,
                swiperOption: {
                    spaceBetween: 20,
                    autoplay: {
                      delay: 6000,
                    },
                    loop: true,
                    loopedSlides: 9,
                    autoplayDisableOnInteraction: true,
                    breakpoints: {
                        1499:{
                            slidesPerView : 5
                        },

                        992:{
                            slidesPerView : 3
                        },

                        576:{
                            slidesPerView : 2
                        },

                        300:{
                            slidesPerView : 1
                        }
                    }
                }
            }
        }
    };
</script>
